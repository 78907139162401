/* fomantic colors */
:root {
  --color-red: #DB2828;
  --color-orange: #f2711c;
  --color-yellow: #FFD700;
  --color-olive: #b5cc18;
  --color-green: #016936;
  --color-teal: #008080;
  --color-blue: #0E6EB8;
  --color-violet: #5100ff;
  --color-purple: #B413EC;
  --color-pink: #FF1493;
  --color-brown: #a5673f;
  --color-grey: #A0A0A0;
  --color-black: #000000;

  --heatmap-color: #1e6823;
}

@font-face {
    font-family: ComicNeue;
    src: url("/otf/comicneue/regular.otf") format("opentype");
}

@font-face {
    font-family: ComicNeue;
    font-weight: bold;
    src: url("/otf/comicneue/bold.otf") format("opentype");
}

@font-face {
    font-family: ComicNeue;
    font-weight: lighter;
    src: url("/otf/comicneue/light.otf") format("opentype");
}

@font-face {
    font-family: Papyrus;
    src: url("/woff2/papyrus/regular.woff2") format("woff2");
}

@font-face {
    font-family: MahRocks;
    src: url("/otf/rocks/regular.otf") format("opentype");
}

@font-face {
    font-family: ShinGoPro;
    src: url("/otf/shingo/regular.otf") format("opentype");
}

@font-face {
    font-family: ShinGoPro;
    font-weight: bold;
    src: url("/otf/shingo/bold.otf") format("opentype");
}

@font-face {
    font-family: ShinGoPro;
    font-weight: lighter;
    src: url("/otf/shingo/light.otf") format("opentype");
}

@font-face {
    font-family: NewRodinPro;
    src: url("/otf/newrodin/doublebold.otf") format("opentype");
}

@font-face {
    font-family: NewRodinPro;
    font-weight: bold;
    src: url("/otf/newrodin/bold.otf") format("opentype");
}

@font-face {
    font-family: NewRodinPro;
    font-weight: lighter;
    src: url("/otf/newrodin/light.otf") format("opentype");
}

body.aprilfools {
  background-color: #FFFF00;
}

.aprilfools {
  background-color: var(--color-yellow);
  color: var(--color-red);

  --color-red: #FF0000;
  --color-orange: #FF8800;
  --color-yellow: #FFFF00;
  --color-olive: #88FF00;
  --color-green: #00FF00;
  --color-teal: #00FFFF;
  --color-blue: #0000FF;
  --color-violet: #6435c9;
  --color-purple: #FF00FF;
  --color-pink: #FF1493;
  --color-brown: #a5673f;
  --color-grey: #d0d302;
  --color-black: #000000;
}

.aprilfools .header {
  color: var(--color-purple) !important;
}

.aprilfools a, .aprilfools .ui.list>.item a.header {
  color: var(--color-blue) !important;
}

.aprilfools .ui.menu.purple.inverted,
.aprilfools .ui.menu.violet.inverted,
.aprilfools .ui.menu.blue.inverted {
    background-color: var(--color-purple);
    color: var(--color-red) !important;
}

.aprilfools .ui.menu.purple.inverted .header,
.aprilfools .ui.menu.violet.inverted .header,
.aprilfools .ui.menu.blue.inverted .header {
    color: var(--color-yellow) !important;
}

.aprilfools .ui.menu.purple.inverted svg,
.aprilfools .ui.menu.violet.inverted svg,
.aprilfools .ui.menu.blue.inverted svg {
    color: var(--color-green) !important;
}

.aprilfools .ui.progress {
    background-color: var(--color-grey) !important;
}

.aprilfools .ui.card .content,
.aprilfools .ui.segment {
  border-color: var(--color-red);
  background-color: var(--color-yellow);
}

.aprilfools svg .color-empty {
  fill: var(--color-grey);
}

.aprilfools table.ui.table {
    border-color: var(--color-red);
}

.aprilfools table.ui.table * {
    border-color: var(--color-red);
}

.aprilfools .ui.card,
.aprilfools .ui.pagination.menu a,
.aprilfools .ui.pagination.menu a.active
{
    box-shadow: 0 1px 3px 0 var(--color-red), 0 0 0 1px var(--color-red);
}

.aprilfools .ui.card *,
.aprilfools .ui.segment
{
  background-color: var(--color-yellow);
  color: var(--color-red);
}

.aprilfools .ui.segment * {
  color: var(--color-red) !important;
}

.aprilfools .ui.pagination.menu a {
    background-color: var(--color-yellow);
    color: var(--color-red) !important;
}

.aprilfools .ui.pagination.menu a.active {
    background-color: var(--color-red);
    color: var(--color-yellow) !important;
    font-weight: bold;
}

.aprilfools .ui.segment.secondary,
.aprilfools .ui.segment.secondary * {
  background-color: var(--color-red);
  color: var(--color-yellow) !important;
}

.aprilfools .ui.menu .dropdown.item .menu {
  background-color: var(--color-red);
}

.aprilfools .ui.menu .dropdown.item .menu .item {
  color: var(--color-yellow) !important;
}

.aprilfools table.ui.table thead,
.aprilfools table.ui.table th {
  background-color: var(--color-red) !important;
  color: var(--color-yellow) !important;
}

.aprilfools .ui.modal > .content {
  background-color: var(--color-yellow);
  color: var(--color-red);
}

.aprilfools .ui.modal > .header,
.aprilfools .ui.modal > .actions {
  background-color: var(--color-yellow);
  color: var(--color-red);
}

.aprilfools .ui.header.red {
  color: var(--color-red) !important;
}

.aprilfools .ui.red.label,
.aprilfools .ui.button.red,
.aprilfools .ui.progress.red .bar,
.aprilfools .ui.table td.error,
.aprilfools .ui.table td.error *,
.aprilfools .ui.table td.red,
.aprilfools .ui.table td.red * {
  background: var(--color-red) !important;
  color: var(--color-yellow) !important;
}

.aprilfools .ui.header.blue {
  color: var(--color-blue) !important;
}

.aprilfools .ui.blue.label,
.aprilfools .ui.button.blue,
.aprilfools .ui.progress.blue .bar,
.aprilfools .ui.table td.blue,
.aprilfools .ui.table td.blue * {
  background: var(--color-blue) !important;
  color: var(--color-yellow) !important;
}

.aprilfools .ui.header.green {
  color: var(--color-green) !important;
}

.aprilfools .ui.green.label,
.aprilfools .ui.button.green,
.aprilfools .ui.progress.green .bar,
.aprilfools .ui.table td.success,
.aprilfools .ui.table td.success *,
.aprilfools .ui.table td.green,
.aprilfools .ui.table td.green * {
  background: var(--color-green) !important;
  color: var(--color-red) !important;
}

.aprilfools .ui.header.teal {
  color: var(--color-teal) !important;
}

.aprilfools .ui.teal.label,
.aprilfools .ui.button.teal,
.aprilfools .ui.progress.teal .bar,
.aprilfools .ui.table td.teal,
.aprilfools .ui.table td.teal * {
  background: var(--color-teal) !important;
  color: var(--color-pink) !important;
  font-weight: bold;
}

.aprilfools .ui.header.pink {
  color: var(--color-pink) !important;
}

.aprilfools .ui.pink.label,
.aprilfools .ui.button.pink,
.aprilfools .ui.progress.pink .bar,
.aprilfools .ui.table td.pink,
.aprilfools .ui.table td.pink * {
  background: var(--color-pink) !important;
  color: var(--color-yellow) !important;
}

.aprilfools .ui.header.purple {
  color: var(--color-purple) !important;
}

.aprilfools .ui.purple.label,
.aprilfools .ui.button.purple,
.aprilfools .ui.progress.purple .bar,
.aprilfools .ui.table td.purple,
.aprilfools .ui.table td.purple * {
  background: var(--color-purple) !important;
  color: var(--color-yellow) !important;
}

.aprilfools .ui.header.olive {
  color: var(--color-olive) !important;
}

.aprilfools .ui.olive.label,
.aprilfools .ui.button.olive,
.aprilfools .ui.progress.olive .bar,
.aprilfools .ui.table td.olive,
.aprilfools .ui.table td.olive * {
  background: var(--color-olive) !important;
  color: var(--color-red) !important;
}

.aprilfools .ui.header.yellow {
  color: var(--color-grey) !important;
}

.aprilfools .ui.progress.yellow .bar,
.aprilfools .ui.progress.yellow .bar * {
    border: 1px;
    border-color: var(--color-grey);
    background: var(--color-yellow) !important;
    color: var(--color-grey) !important;
}

.aprilfools .ui.yellow.label,
.aprilfools .ui.button.yellow,
.aprilfools .ui.table td.yellow,
.aprilfools .ui.table td.yellow * {
  background: var(--color-yellow) !important;
  color: var(--color-purple) !important;
}

.aprilfools .ui.header.orange {
  color: var(--color-orange) !important;
}

.aprilfools .ui.orange.label,
.aprilfools .ui.button.orange,
.aprilfools .ui.progress.orange .bar,
.aprilfools .ui.table td.orange,
.aprilfools .ui.table td.orange * {
  background: var(--color-orange) !important;
  color: var(--color-green) !important;
  font-weight: bold;
}

.aprilfools .ui.header.grey {
  color: var(--color-grey) !important;
}

.aprilfools .ui.label,
.aprilfools .ui.grey.label,
.aprilfools .ui.button.grey,
.aprilfools .ui.grey:not(.header),
.aprilfools .ui.table td.warning,
.aprilfools .ui.table td.warning *,
.aprilfools .ui.table td.grey,
.aprilfools .ui.table td.grey * {
  background: var(--color-grey) !important;
  color: var(--color-black) !important;
}

.aprilfools .ui.input input::placeholder,
.aprilfools .ui.input textarea::placeholder {
    color: var(--color-grey);
}

.aprilfools .ui textarea,
.aprilfools .ui.selection.dropdown,
.aprilfools .ui.input input {
    background-color: var(--color-yellow);
    border-color: var(--color-red);
    color: var(--color-red);
}

.aprilfools .ui textarea:hover,
.aprilfools .ui textarea:focus,
.aprilfools .ui textarea:active,
.aprilfools .ui.input input:hover,
.aprilfools .ui.input input:focus,
.aprilfools .ui.input input:active {
    background-color: var(--color-red);
    border-color: var(--color-yellow);
    color: var(--color-yellow);
}

.aprilfools .ui.message {
    border-color: var(--color-blue);
    color: var(--color-yellow);
    background-color: var(--color-red);
}

.aprilfools .ui.message.green {
    border-color: var(--color-blue);
    color: var(--color-red);
    background-color: var(--color-green);
}

.aprilfools table.ui.table tbody,
.aprilfools table.ui.table td {
  background-color: var(--color-yellow) !important;
  color: var(--color-red) !important;
}

.aprilfools1,
.aprilfools1 .ui:not(.icon),
.aprilfools1 .ui.button,
.aprilfools1 h1,
.aprilfools1 h2,
.aprilfools1 h3,
.aprilfools1 h4,
.aprilfools1 h5,
.aprilfools1 h6,
.aprilfools1 .header,
.aprilfools1 .title,
.aprilfools .ui.input input,
.aprilfools .ui textarea,
.aprilfools1 svg text {
    font-family: ShinGoPro !important;
}

.aprilfools2,
.aprilfools2 .ui:not(.icon),
.aprilfools2 .ui.button,
.aprilfools2 h1,
.aprilfools2 h2,
.aprilfools2 h3,
.aprilfools2 h4,
.aprilfools2 h5,
.aprilfools2 h6,
.aprilfools2 .header,
.aprilfools2 .title,
.aprilfools2 svg text {
    font-family: ComicNeue !important;
}

.aprilfools3,
.aprilfools3 .ui:not(.icon),
.aprilfools3 .ui.button,
.aprilfools3 h1,
.aprilfools3 h2,
.aprilfools3 h3,
.aprilfools3 h4,
.aprilfools3 h5,
.aprilfools3 h6,
.aprilfools3 .header,
.aprilfools3 .title,
.aprilfools3 svg text {
    font-family: MahRocks !important;
}

.aprilfools4,
.aprilfools4 .ui:not(.icon),
.aprilfools4 .ui.button,
.aprilfools4 h1,
.aprilfools4 h2,
.aprilfools4 h3,
.aprilfools4 h4,
.aprilfools4 h5,
.aprilfools4 h6,
.aprilfools4 .header,
.aprilfools4 .title,
.aprilfools4 svg text {
    font-family: Papyrus !important;
}

.aprilfools5,
.aprilfools5 .ui:not(.icon),
.aprilfools5 .ui.button,
.aprilfools5 h1,
.aprilfools5 h2,
.aprilfools5 h3,
.aprilfools5 h4,
.aprilfools5 h5,
.aprilfools5 h6,
.aprilfools5 .header,
.aprilfools5 .title,
.aprilfools5 svg text {
    font-family: NewRodin !important;
}

/* custom css */
:root {
  --vh: 1vh;
}
#__next {
  height: calc(100 * var(--vh)) !important;
}

.arena-label {
  display: inline !important;
}

.dan-label {
  display: inline !important;
  text-decoration: underline dotted;
}

.qpro-stack {
  width: 305px;
  height: 352px;
  background-repeat: no-repeat !important;
}

.image-stack {
  position: relative;
}

.image-stack img {
  position: absolute;
  top: 0px;
  left: 0px;
}

.image-stack img:first-child {
  position: relative;
}

.image-stack .qpro-leg {
  top: 220px;
  left: 70px;
}

.autofit-width {
  width: 100%;
}

img.ifs {
  clip-path: inset(1px 1px 1px 1px);
}

.bst-judge-pad {
  margin-top: 6px;
}

div.qpro.profile.viewer {
  width: calc(305px * 0.6);
  height: calc(352px * 0.6);
  position: relative;
  top: -50px;
  left: -35px;
  transform: scale(0.6);
}

div.qpro.profile.viewer.small {
  width: calc(305px * 0.4);
  height: calc(352px * 0.4);
  position: relative;
  top: -50px;
  left: -35px;
  transform: scale(0.4);
}

div.qpro.list.viewer {
  width: calc(305px * 0.15);
  height: calc(352px * 0.15);
  position: relative;
  top: -25px;
  left: -15px;
  transform: scale(0.15);
}

div.qpro.cell.competition {
  width: calc(305px * 0.20);
  height: calc(352px * 0.20);
  position: relative;
  top: -18px;
  left: -15px;
  transform: scale(0.20);
}

.image-stack .qpro-arm-r {
  top: 157px;
  left: -8px;
}

.image-stack .qpro-hand-r {
  left: -45px;
  top: -20px;
}

.image-stack .qpro-hand-l {
  left: 100px;
  top: -20px;
}

.image-stack .qpro-arm-l {
  top: 157px;
  left: 118px;
}

.image-stack .qpro-face {
  top: 37px;
  left: 55px;
}

.image-stack .qpro-head {
  top: -5px;
}

li.paginate-button {
  display: inline;
}

.react-calendar-heatmap .red { --heatmap-color: var(--color-red); }
.react-calendar-heatmap .orange { --heatmap-color: var(--color-orange); }
.react-calendar-heatmap .yellow { --heatmap-color: var(--color-yellow); }
.react-calendar-heatmap .olive { --heatmap-color: var(--color-olive); }
.react-calendar-heatmap .green { --heatmap-color: var(--color-green); }
.react-calendar-heatmap .teal { --heatmap-color: var(--color-teal); }
.react-calendar-heatmap .blue { --heatmap-color: var(--color-blue); }
.react-calendar-heatmap .violet { --heatmap-color: var(--color-violet); }
.react-calendar-heatmap .purple { --heatmap-color: var(--color-purple); }
.react-calendar-heatmap .pink { --heatmap-color: var(--color-pink); }
.react-calendar-heatmap .brown { --heatmap-color: var(--color-brown); }
.react-calendar-heatmap .grey { --heatmap-color: var(--color-grey); }
.react-calendar-heatmap .black { --heatmap-color: var(--color-black); }

.react-calendar-heatmap .color-scale-0 { fill: var(--heatmap-color); opacity: 0.10; }
.react-calendar-heatmap .color-scale-1 { fill: var(--heatmap-color); opacity: 0.20; }
.react-calendar-heatmap .color-scale-2 { fill: var(--heatmap-color); opacity: 0.30; }
.react-calendar-heatmap .color-scale-3 { fill: var(--heatmap-color); opacity: 0.40; }
.react-calendar-heatmap .color-scale-4 { fill: var(--heatmap-color); opacity: 0.50; }
.react-calendar-heatmap .color-scale-5 { fill: var(--heatmap-color); opacity: 0.60; }
.react-calendar-heatmap .color-scale-6 { fill: var(--heatmap-color); opacity: 0.70; }
.react-calendar-heatmap .color-scale-7 { fill: var(--heatmap-color); opacity: 0.80; }
.react-calendar-heatmap .color-scale-8 { fill: var(--heatmap-color); opacity: 0.90; }
.react-calendar-heatmap .color-scale-9 { fill: var(--heatmap-color); opacity: 1.00; }

/* Font Awesome icon themes */
.duo-analytics {
  --fa-secondary-color: var(--color-blue);
  --fa-primary-color: var(--color-red);
  --fa-primary-opacity: 0.7;
  --fa-secondary-opacity: 1;
}

.duo-brown {
  --fa-primary-color: #fc913f;
  --fa-secondary-color: #fc913f;
}

.duo-user-unregistered {
  --fa-primary-color: #607d8b;
}

.duo-user-normal {
  --fa-primary-color: #2196f3;
}

.duo-user-netop {
  --fa-primary-color: #9c27b0;
}

.duo-user-shop {
  --fa-primary-color: #f44336;
}

.duo-user-union {
  --fa-primary-color: var(--color-pink);
  --fa-secondary-color: var(--color-pink);
}

.spoiler-text {
  padding: 3px;
  opacity: 0.6;
  background: var(--color-grey);
  color: var(--color-grey);
  border-radius: 5px;
  transition: color 0.5s;
  transition-delay: 0s;
}

.spoiler-text:hover {
  color: white;
  transition-delay: 2s;
}

.iidx.judge.pgreat {
  color: var(--color-green);
}
.iidx.judge.great {
  color: var(--color-teal);
}
.iidx.judge.good {
  color: var(--color-blue);
}
.iidx.judge.bad {
  color: var(--color-orange);
}
.iidx.judge.poor {
  color: var(--color-red);
}
.iidx.timing.slow {
  color: var(--color-red);
}
.iidx.timing.fast {
  color: var(--color-blue);
}

.break-block {
  display: inline-block;
}

/* override the default font bolding for first column on stacked tables */
tbody>tr>td {
  font-weight: inherit !important;
}

.compact-rows h6 {
  margin-top: 0px !important;
}
.compact-rows .row {
  padding-bottom: 0px !important;
}
.compact-rows:last-child {
  padding-bottom: 1rem !important;
}

.competition-cell {
  position: relative;
  display: block;
  white-space: nowrap;
  top: -5px;
}

.competition-cell span.score {
  font-size: 20px;
}

.competition-cell span.djname {
  color: gray;
  font-size: 12px;
}

.competition-cell > div.qpro {
  padding-right: 10px;
  position: relative;
  top: 10px;
}

.competition-cell > div {
  min-width: 40px;
  padding-left: 20px;
  display: inline-block;
  height: 100%;
}

.score-data {
  position: relative;
  display: block;
  white-space: nowrap;
}

.score-data > div.grade {
  font-size: 15px;
  padding-right: 10px;
  position: relative;
  top: -6px;
  text-align: center;
}

.score-data > div {
  min-width: 40px;
  display: inline-block;
  height: 100%;
}

@media only screen and (max-width: 767.98px) {
  div.ui.cards {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.no-border {
  border: none !important;
}

.no-bg-table td {
  background-color: transparent !important;
}

[data-copy=""] {
  transition: color 2s, text-shadow 2s;
}

[data-copy="ok"] {
  transition: color 0s, text-shadow 0s;
  color: #0098bb !important;
  text-shadow: 0px 0px 4px #47ffdd;
}

[data-copy="failed"] {
  transition: color 0s;
  color: red;
}

.balance-list > .ui.grid > .row:not(:last-child) {
  padding-bottom: 0;
}

.balance-list > .ui.grid > .row:not(:first-child) {
  padding-top: 0.5rem;
}

.header-controls {
  float: right;
  margin-top: 50px;
}
